import { PropsWithChildren, useEffect } from 'react';
import Head from 'next/head';
import { Broadcast, House } from 'phosphor-react';
import cx from 'classnames';

import ReactHeap from 'utils/heap';
import useColorScheme from '@/hooks/useColorScheme';
import { Graph } from '@phosphor-icons/react';
import Login from '../login';
import { HEAP_ID } from '../../buildConfig/processEnv';
import { useAppContext } from '../../contexts/appContext';
import AppLoading from '../appLoading';
import { Navigation } from './Navigation';

interface Props extends PropsWithChildren {
    title: string;
    style?: React.CSSProperties;
    className?: string;
}

const HeadSection = ({ title }) => (
    <Head>
        <title>{title}</title>
        <link rel="shortcut icon" href="/images/favicon.png" />
    </Head>
);
const Home = (active: boolean) => <House size={20} weight={active ? 'fill' : 'light'} />;
const Segments = (active: boolean) => <Graph size={20} weight={active ? 'fill' : 'light'} />;
const Signals = (active: boolean) => <Broadcast size={20} weight={active ? 'fill' : 'light'} />;

export default function ResponsiveLayoutUlysses({ children, title, className = '', style }: Props) {
    const { rawUser, user, fetchingToken } = useAppContext();
    const { colorScheme } = useColorScheme();

    useEffect(() => {
        if (HEAP_ID) {
            ReactHeap.initialize(HEAP_ID);
        }
    }, []);

    if (fetchingToken) {
        return <AppLoading>Authenticating user</AppLoading>;
    }

    if (!user || !rawUser || (!user.token && !user.siberiaToken) || !user.isLoggedIn) {
        return <Login />;
    }

    if (!user?.groups && !!user?.siberiaToken && !rawUser?.groups) {
        return <AppLoading>Loading user data</AppLoading>;
    }

    const navigationItems = [
        {
            Icon: Home,
            label: 'Home',
            link: '/',
        },
        {
            Icon: Segments,
            label: 'Segment analysis',
            link: '/segment-analysis',
        },
        {
            Icon: Signals,
            label: 'Signals',
            link: '/signals',
            disabled: true,
        },
    ];

    return (
        <div className="flex h-screen overflow-hidden" data-mode={colorScheme}>
            <HeadSection title={title} />
            {/* Navigation Badge */}
            {/* <div className="hidden md:flex">
                <Navigation
                    direction="vertical"
                    items={navigationItems}
                    user={user}
                    padding="p-6"
                />
            </div> */}

            {/* Main Content Area */}
            <div className="ml-1 h-full w-fit flex-1 overflow-y-auto md:ml-[72px]">
                <div className="flex h-full flex-col">{children}</div>
            </div>
        </div>
    );
}
