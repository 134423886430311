import { Barcode, CaretDown, IconProps, Storefront } from 'phosphor-react';
import cx from 'classnames';

import { Text } from 'andromeda';
import { Circle } from 'andromeda/Circle';
import colors from '@/constants/colors';
import { HamburgerMenu } from './HamburgerMenu';

const Filter = ({
    name,
    icon: IconComponent,
}: {
    name: string;
    icon: React.ComponentType<IconProps>;
}) => (
    <div className="flex items-center gap-x-1">
        <IconComponent size={16} color="#87A0F3" />
        <p>{name}</p>
        <CaretDown size={12} />
    </div>
);

const UpdateText = ({ className }: { className?: string }) => (
    <div className={cx('flex h-4 items-center', className)}>
        <p className="text-xs text-neutral-400">
            Last Update: <span className="text-black">as of Aug 31</span>
        </p>
        <Circle color={colors.green} size={8} className="ml-2 animate-pulse" />
    </div>
);

interface HeaderProps {
    pageName: string;
    message?: string;
    icon: React.ComponentType<IconProps>;
    showFilters?: boolean;
}

export const Header = ({ pageName, message, icon: IconComponent, showFilters }: HeaderProps) => (
    <div className="mt-12 flex w-full flex-col gap-y-5 pr-5 lg:gap-y-8 lg:pr-0">
        <div className="flex flex-row justify-between">
            <div className="flex items-center justify-between md:justify-normal">
                <div className="mr-3 flex items-center gap-x-[10px]">
                    <IconComponent size={24} weight="fill" color="black" />
                    <Text type="h3" className="!text-[32px] !text-black">
                        {pageName}
                    </Text>
                </div>
                {showFilters && (
                    <div className="mb-1 ml-6 flex cursor-pointer gap-x-4 self-end text-xs">
                        <Filter name="All products" icon={Barcode} />
                        <Filter name="All stores" icon={Storefront} />
                    </div>
                )}
            </div>
            <UpdateText className="hidden pr-10 lg:flex" />
        </div>
        {message && (
            <Text type="h4" className="!text-[24px] !text-black">
                {message}
            </Text>
        )}
        <UpdateText className="lg:hidden" />
        <HamburgerMenu className="md:hidden" />
    </div>
);
