import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { Broadcast, Graph, House, User } from 'phosphor-react';
import cx from 'classnames';
import Hamburger from 'hamburger-react';

import { useAppContext } from '@/contexts/appContext';
import { useGlobalContext } from '@/contexts/GlobalContext';
import { Navigation } from './Navigation';

const Home = (active: boolean) => <House size={20} weight={active ? 'fill' : 'light'} />;
const Segments = (active: boolean) => <Graph size={20} weight={active ? 'fill' : 'light'} />;
const Signals = (active: boolean) => <Broadcast size={20} weight={active ? 'fill' : 'light'} />;
const Settings = (active: boolean) => <User size={20} weight={active ? 'fill' : 'light'} />;

const navigationItems = [
    {
        Icon: Home,
        label: 'Home',
        link: '/',
    },
    {
        Icon: Segments,
        label: 'Segment analysis',
        link: '/segment-analysis',
    },
    {
        Icon: Signals,
        label: 'Signals',
        link: '/signals',
        disabled: true,
    },
    {
        Icon: Settings,
        label: 'Settings',
        link: '/settings',
        disabled: true,
    },
];

const MobileNavButton = () => {
    const { isMobileNavOpen, toggleMobileNav } = useGlobalContext(state => state);

    return (
        <button
            type="button"
            className="fixed right-3 top-0.5 z-50 appearance-none"
            onClick={() => toggleMobileNav()}
            aria-label="Nav-btn"
        >
            <Hamburger size={20} toggled={isMobileNavOpen} />
        </button>
    );
};

export const HamburgerMenu = ({ className }: { className?: string }) => {
    const { user } = useAppContext();
    const { isMobileNavOpen, closeMobileNav } = useGlobalContext(state => state);
    const pathName = usePathname();

    useEffect(() => {
        closeMobileNav();
    }, [closeMobileNav, pathName]);

    return (
        <div
            className={cx(
                'absolute right-1 z-50',
                {
                    '!top-4': pathName === '/',
                    '!top-12': pathName !== '/',
                },
                className,
            )}
        >
            <MobileNavButton />
            <div
                className={cx(
                    'overflow-hidden transition-all duration-300 ease-in-out',
                    isMobileNavOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0',
                )}
            >
                <Navigation
                    direction="vertical"
                    items={navigationItems}
                    user={user}
                    padding="pr-2"
                    containerClassName="flex flex-col justify-between overflow-hidden items-center"
                />
            </div>
        </div>
    );
};
